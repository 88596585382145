export default [
  {
    text: 'Contact Us',
    link: 'https://www.sprinklr.com/contact-us/',
    external: true,
  },
  // {
  //   text: "We're hiring!",
  //   link: 'https://boards.greenhouse.io/sprinklr/jobs/4168151003',
  //   external: true,
  // },
  {
    text: 'Twitter',
    // link: 'https://twitter.com/sprinklrdesign',
    // external: true,
  },
  {
    text: 'Dribbble',
    link: 'https://dribbble.com/sprinklrdesign',
    external: true,
  },
  {
    text: 'sprinklr.com',
    link: 'http://sprinklr.com',
    external: true,
  },
]
