import { Link } from 'gatsby';
import styled from '@emotion/styled';
import { css } from '@emotion/core';

const NormalizedLink = styled(Link)`
  color: initial;
  text-decoration: none;
`;

const removeLinkStyles = () => css`
  color: initial;
  text-decoration: none;
`;

export default NormalizedLink;
export { removeLinkStyles };
