import styled from '@emotion/styled';
import media from './media';

const FooterGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(4, 33.333vw);
  grid-template-areas:
    '. . block-1'
    'block-2 block-2 .'
    'block-3 block-3 block-3'
    'block-4 block-5 block-5';
  
  /* 768 */
  ${media.md`
    /* Grid Styles */
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: repeat(4, 16.667vh);
    /* Grid Layout */
    grid-template-areas:
      '. . . block-1'
      '. block-2 block-2 .'
      'block-3 block-3 block-3 .'
      'block-4 . block-5 block-5';
  `}

  ${media.lg`
    /* Grid Styles */
    grid-template-columns: repeat(5, 1fr);
    grid-template-rows: repeat(3, 210px);
    grid-template-rows: repeat(3, 25vh);
    
    /* Max Width */
    /* max-width: 1400px;
    max-height: 900px; */
    margin: 0 auto;

    /* Grid Layout */
    grid-template-areas: 
      'social social . . block-1'
      'social social block-2 block-3 block-4'
      'social social . block-5 .';

  `};

  /* ${media.xl`
    grid-template-rows: repeat(3, 225px);
  `} */
`;

export default FooterGrid;
