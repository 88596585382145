import styled from '@emotion/styled';
import { css } from '@emotion/core';
import media from './media';

const GenericWrapper = styled.div`
  position: relative;
  width: 100%;
  margin: 0 auto;
  padding: 0 2rem;
  padding: 0 1.5rem;
  padding: 0 24px;

  /* Limit to starting tablet size;
   * 600 + Padding) 
   */
  max-width: 664px;

  /* tablet size */
  ${media.md`
    padding: 0;
    /* really inconsistent in the designs right now */
    width: 78.125%;
    max-width: 1200px;
  `}

  ${({ debug }) =>
    debug &&
    css`
      border: 1px solid purple;
    `}
`;

export default GenericWrapper;
