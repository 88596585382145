import { css } from '@emotion/core';
import theme from '../gatsby-plugin-theme-ui';

// Alias Breakpoints
export const sizes = {
  xl: theme.breakpoints[3], // 1400
  lg: theme.breakpoints[2], // 1024
  md: theme.breakpoints[1], // 768
  sm: theme.breakpoints[0], // 412
};

export default Object.keys(sizes).reduce((acc, label) => {
  acc[label] = (...args) => css`
    @media (min-width: ${sizes[label]}) {
      ${css(...args)};
    }
  `;
  return acc;
}, {});
