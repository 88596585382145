/** @jsx jsx */
import { jsx } from 'theme-ui';
import React from 'react';
import PropTypes from 'prop-types';
import Header from './Header';
import SEO from './Seo';
import './layout.css';
import Footer from './Footer';
import PageScrollProggressBar from './PageScrollProgressBar';

// Util Control
const showSizr = false;
const showScrollProgress = false;

const Layout = ({ children, hideFooter, staticHeader, hackForceActiveSystemNavLink, seo }) => {
  const target = React.createRef();

  return (
    <>
      <SEO {...seo} />
      <Header
        target={target}
        isResponsive={!staticHeader}
        hackForceActiveSystemNavLink={hackForceActiveSystemNavLink}
      />
      <div ref={target}>{children}</div>
      {!hideFooter && <Footer />}
      {/* Debug Stuff */}
      {showScrollProgress && <PageScrollProggressBar target={target} />}
      {showSizr && <div sx={{ variant: 'sizr' }} />}
    </>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  hideFooter: PropTypes.bool,
  staticHeader: PropTypes.bool,
  hackForceActiveSystemNavLink: PropTypes.bool,
  seo: PropTypes.any,
};

export default Layout;
