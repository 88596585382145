/** @jsx jsx */
import { jsx, Styled } from 'theme-ui';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { Arrow } from '../assets/VectorAssets';
import FooterGrid from './FooterGrid';
import DATA from '../data/footer';
import media from './media';

const GalleryWrapper = styled.div`
  grid-area: social;
  align-items: center;
  justify-content: center;
  display: none;

  ${media.lg`
    display: flex;
  `}
`;

const Footer = () => (
  <div
    sx={{
      variant: 'invert',
      position: 'relative',
    }}
  >
    <FooterGrid>
      <GalleryWrapper>
        <div id="sprinklr-embed-control-734-ZW1iZWQ6NWRkZGE1OTc4ZTZmMjc2NzE1YjM0OWQz" className="swe_embed_controls" />
        <div id="sprinklr-embed-734-ZW1iZWQ6NWRkZGE1OTc4ZTZmMjc2NzE1YjM0OWQz" className="swe_embed" />
      </GalleryWrapper>
      {DATA.map((x, i) =>
        !x.link ? (
          <div
            sx={{
              variant: 'text.removeLinkStyles',
              color: 'inherit',
              padding: '24px',
              gridArea: `block-${i + 1}`,
              bg: theme => `${theme.colors.scale.neutral[i]}`,
              position: 'relative',
            }}
            to={x.link}
            key={i}
          >
            <Styled.h4></Styled.h4>
          </div>
        ) : (
          <a
            sx={{
              variant: 'text.removeLinkStyles',
              color: 'inherit',
              padding: '24px',
              gridArea: `block-${i + 1}`,
              bg: theme => `${theme.colors.scale.neutral[i]}`,
              position: 'relative',
              svg: {
                opacity: '0',
                transition: '.3s ease opacity',
              },
              '&:hover': {
                svg: {
                  opacity: '1',
                },
              },
            }}
            href={x.link}
            target="_blank"
            rel="noopener noreferrer"
            key={i}
          >
            <Styled.h4>{x.text}</Styled.h4>
            <span
              sx={{
                position: 'absolute',
                bottom: '24px',
                right: '24px',
                mb: '0',
                display: 'inline-block',
                'svg g': {
                  fill: 'background',
                },
              }}
              alt="This link opens in a new tab"
            >
              <Arrow />
            </span>
          </a>
        )
      )}
    </FooterGrid>
  </div>
);

Footer.propTypes = {};

Footer.defaultProps = {};

export default Footer;
