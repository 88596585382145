import styled from '@emotion/styled';
import { css } from '@emotion/core';
import { Link } from 'gatsby';
import GenericWrapper from '../Wrapper';
import { removeLinkStyles } from '../Link';
import media, { sizes } from '../media';

const StyledHeader = styled.div`
  position: fixed;
  top: 0;
  width: 100vw;
  z-index: 99999;
  background: ${props => props.theme.colors.text};
`;

const HeaderWrapper = styled(GenericWrapper)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 0 0 24px;
  position: relative;
  z-index: 1;
  /* this is no longer a generic wrapper */
  max-width: initial;

  ${media.md`
    /* 
      * set max width for grid; 
      * adjust this if the grid grows
      */
    /* max-width: 1400px; */
    /* max-width: initial; */
    width: 100%;

    /* overriding generic wrapper */
    padding-left: 24px; 
    /* padding-right: 0px; */
  `}

  ${media.lg`
    /* background: purple; */
    /* not needed for cencter */
    /* padding-left: 48px;
    padding-right: 48px; */
    padding-right: 24px;
  `}
  ${media.lg`
    /* not needed for cencter */
    /* padding-right: 24px; */
  `}
  @media (min-width: 1224px) {
    padding-left: 0;
    padding-right: 0;
  }
`;

const HeaderBrand = styled(Link)`
  /* position: relative; */
  /* z-index: 10; */
  /* display: inline-flex; */
  /* flex-direction: row;
  justify-content: flex-start;
  align-items: center; */
  /* remove default link styles */
  /* ${removeLinkStyles()} */

  padding: 24px 0;
  z-index: 2;

  .logo--text {
    display: none;
  }

  ${media.lg`
    padding: 16px 0;
    
    .logo--splash {
      display: none;
    }
    
    .logo--text {
      display: block;
    }
  `}
`;

export { StyledHeader, HeaderWrapper as Wrapper, HeaderBrand as Brand };
