import styled from '@emotion/styled';
import { css } from '@emotion/core';
import PropTypes from 'prop-types';
import media from '../media';

// Burger styles snatched from CodePen => https://codepen.io/husnimun/pen/pJvEeL

const burgerBar = {
  width: 24,
  height: 2,
  spacing: 6,
  units: 'px',
  color: {
    default: 'white',
    active: 'white',
  },
};

const getBurgerSpec = spec => `${burgerBar[spec]}${burgerBar.units}`;

const burgerTransition = (delay = '0', duration = '300ms') => `${duration} ${delay}ms cubic-bezier(0.23, 1, 0.32, 1)`;

const defaultBurgerBarStyles = () => css`
  width: ${getBurgerSpec('width')};
  height: ${getBurgerSpec('height')};
  background: ${burgerBar.color.default};
  border-radius: ${`${burgerBar.height / 2}${burgerBar.units}`};
  border-radius: 5px;
`;

const BurgerWrapper = styled.div`

  ${props =>
    !props.vegan &&
    css`
      position: relative;
      padding: 24px;
      border-radius: 0;
      transition: all 0.25s cubic-bezier(0.6, -0.28, 0.74, 0.05);
    `}

  ${props =>
    props.vegan &&
    css`
      position: fixed;
      bottom: 48px;
      right: 24px;
      border-radius: 50%;
      padding: 17px 12px;
      box-shadow: 0 4px 6px 0 rgba(0, 0, 0, 0.15);
      transition: all 0.25s cubic-bezier(0.6, -0.28, 0.74, 0.05);
      ${'' /* transition: all 2s cubic-bezier(0.6, -0.28, 0.74, 0.05); */}
      background: ${props.theme.colors.text};

      ${
        props.float
          ? css`
              ${'' /* background: purple; */}
              transform: translateX(150%);
            `
          : css`
              background: rgb(10, 10, 20);
              ${'' /* background: teal; */}
              transform: translateX(0%);
            `
      }
    `}
  
  /* hide above tablet */
  ${media.lg`
    display: none;
  `}
`;

const BurgerBox = styled.div`
  display: block;
  width: ${burgerBar.width}px;
  height: ${`${burgerBar.height + burgerBar.spacing * 2}${burgerBar.units}`};
  cursor: pointer;
`;

const StyledBurger = styled.div`
  position: relative;
  ${defaultBurgerBarStyles()};
  transform: translateY(${getBurgerSpec('spacing')});

  &::before,
  &::after {
    content: '';
    position: absolute;
    ${defaultBurgerBarStyles()};
    left: 0;
  }

  &::before {
    bottom: ${getBurgerSpec('spacing')};
    transition: bottom ${burgerTransition(300)}, transform ${burgerTransition()};
  }

  &::after {
    top: ${getBurgerSpec('spacing')};
    transition: top ${burgerTransition(300)}, transform ${burgerTransition()};
  }

  ${props =>
    props.toggle &&
    css`
      background: rgba(255, 255, 0, 0);

      &::before {
        background: ${burgerBar.color.active};
        bottom: 0;
        transform: rotate(-45deg);
        transition: bottom ${burgerTransition()}, transform ${burgerTransition(300)};
      }

      &::after {
        background: ${burgerBar.color.active};
        top: 0;
        transform: rotate(45deg);
        transition: top ${burgerTransition(0)}, transform ${burgerTransition(300)};
      }
    `}
`;

const Burger = ({ toggle, onClick, float, show, vegan }) => (
  <BurgerWrapper onClick={onClick} float={float} show={show} vegan={vegan}>
    <BurgerBox>
      <StyledBurger toggle={toggle} />
    </BurgerBox>
  </BurgerWrapper>
);

Burger.propTypes = {
  toggle: PropTypes.bool,
  onClick: PropTypes.func,
  float: PropTypes.bool,
  show: PropTypes.bool,
  vegan: PropTypes.bool,
};

Burger.defaultProps = {
  show: true,
  float: false,
};

export default Burger;
