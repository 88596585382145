/** @jsx jsx */
import { jsx, useColorMode } from 'theme-ui'
import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import PropTypes from 'prop-types'
import { Nav, NavLink } from '../Nav'
import { Logo, LogoText, SprinklrDesign } from '../../assets/VectorAssets.js'
import { StyledHeader, Wrapper, Brand } from './HeaderBar'
import Burger from './Burger'
import ColorModeSwitch from '../ColorModeSwitch'
import { Styled } from 'theme-ui'

const SITE_NAV_QUERY = graphql`
  query {
    site {
      siteMetadata {
        globalNavigation {
          link
          text
          activeColor
        }
      }
    }
  }
`

const Header = ({ target, isResponsive, hackForceActiveSystemNavLink }) => {
  // Nav Active
  const [navActive, setNavActive] = React.useState(false)

  // Toggle Nav
  const toggleNav = () => {
    setNavActive(!navActive)
  }

  // Header visibility hook, set default based on isResponsive prop
  const [headerVisibility, setHeaderVisibility] = React.useState(!isResponsive)

  return (
    <StaticQuery
      query={SITE_NAV_QUERY}
      render={data => {
        const { globalNavigation: GLOBAL_NAV } = data.site.siteMetadata
        return (
          <>
            <StyledHeader>
              <Wrapper>
                <Brand to="/">
                  <SprinklrDesign />
                </Brand>

                {false && <ColorModeSwitch />}

                <Nav active={navActive}>
                  <NavLink
                    to="/"
                    onClick={() => setNavActive(false)}
                    sx={{
                      display: ['block', 'block', 'block', 'none'],
                      fontFamily: [
                        'medium',
                        'medium',
                        'medium',
                        'medium',
                        'medium',
                      ],
                      '&.active': {
                        color: 'tertiary',
                      },
                    }}
                    activeClassName="active"
                  >
                    Home
                  </NavLink>
                  {GLOBAL_NAV.map(({ text, link, activeColor }, i) => (
                    <NavLink
                      to={`/${link}`}
                      key={i}
                      onClick={() => setNavActive(false)}
                      sx={{
                        fontFamily: [
                          'medium',
                          'medium',
                          'medium',
                          'medium',
                          'medium',
                        ],
                        '&.active': {
                          // color: ['background', 'background', 'secondary'],
                          color: `${activeColor}`,
                        },
                        body: {
                          border: '10px solid green',
                        },
                      }}
                      activeClassName="active"
                      partiallyActive
                      className={
                        i === 1 && hackForceActiveSystemNavLink
                          ? 'active classNameHere'
                          : ''
                      }
                    >
                      {text}
                    </NavLink>
                  ))}
                  {/* <NavLink
                    to={`https://boards.greenhouse.io/sprinklr/jobs/4168151003`}
                    onClick={() => setNavActive(false)}
                    sx={{
                      fontFamily: [
                        'medium',
                        'medium',
                        'medium',
                        'medium',
                        'medium',
                      ],
                      '&:hover': {
                        // color: ['background', 'background', 'secondary'],
                        color: 'rgba(255,255,255,0.6)',
                      },
                    }}
                    activeClassName="active"
                    partiallyActive
                    className={'active'}
                    target="_blank"
                  >
                    {"We're Hiring!"}
                  </NavLink> */}
                </Nav>

                <Burger onClick={toggleNav} toggle={navActive} />
                {false && (
                  <Burger
                    onClick={toggleNav}
                    toggle={navActive}
                    float={headerVisibility}
                    vegan
                  />
                )}
              </Wrapper>
            </StyledHeader>
          </>
        )
      }}
    />
  )
}

Header.propTypes = {
  target: PropTypes.any,
  isResponsive: PropTypes.bool,
  hackForceActiveSystemNavLink: PropTypes.bool,
}

Header.defaultProps = {
  isResponsive: false,
}

export default Header
