// import React from 'react'
import { Link } from 'gatsby'
import styled from '@emotion/styled'
import { css } from '@emotion/core'
import media from './media'
import { removeLinkStyles } from './Link'

const Nav = styled.nav`
  position: fixed;
  bottom: 0;
  width: 100vw;
  left: 0;
  padding: 32px;
  height: 100vh;
  background: #0a0a14;

  display: flex;
  flex-direction: column;
  justify-content: center;
  transition: all 0.5s ease;

  ${({ active }) =>
    active
      ? css`
          transform: translate3d(0, 0, 0);
          box-shadow: 0 -4px 6px 0 rgba(0, 0, 0, 0.15);
        `
      : css`
          transform: translate3d(0, 100%, 0);
          box-shadow: 0 -4px 6px 0 rgba(0, 0, 0, 0);
        `}

  ${media.lg`
    display: flex;
    bottom: initial;
    position: relative;
    transform: translate3d(0, 0, 0);
    flex-direction: row;
    padding: 0;
    height: auto;
    width: auto;
  `}
`

const NavLink = styled(Link)`
  ${removeLinkStyles()};
  color: ${({ theme }) => theme.colors.background60};
  text-decoration: none;
  font-size: 40px;
  line-height: 58px;
  font-family: Helvetica-Bold;
  font-weight: bold;
  text-align: left;
  text-transform: lowercase;
  display: block;
  margin-bottom: 18px;
  transition: color 0.15s ease-in-out;
  &.active {
    color: ${({ theme }) => theme.colors.background};
  }

  &:hover {
    color: ${({ theme }) => theme.colors.background60};
  }

  ${media.lg`
    padding: 4px 8px;
    margin-left: 32px;
    color: white;
    font-size: 14px;
    font-family: SFProText-Medium;
    font-weight: 500;
    line-height: 16px;
    text-align: right;
    text-transform: lowercase;
    letter-spacing: -0.2px;
    margin-bottom: 0;
  `}
`

export { Nav, NavLink }
